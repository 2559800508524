import "lazysizes";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	setupSlideMenu();
	setupBannerNavigation();
	setupSponsorAutoScroll();
	setupSponsorScroll();
	setupMoreNews();
	window.kms_bannerRotate();
	new FloatLabels(".js-float-wrap");
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};

export function setupBannerNavigation(){
	const banners = document.querySelectorAll(".banners");
	if(!banners){
		return;
	}
	banners.forEach(el => {
		const items = el.querySelectorAll(".banner-stage > *");
		if (items == null | items.length == 1){
			el.querySelector(".banner-navigation").style.display = "none";

		}
	});
}
export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			
			// expander.click(function(e) {
			// 	e.stopPropagation();
			// 	$el.toggleClass("expand-active");
			// });
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}
export function setupSponsorScroll(){
	var elms = document.getElementsByClassName("sponsor-scroll");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "loop",
			drag   : true,
			snap   : true,
			pagination: false,
			arrows    : true,
			focus  : "center",
			perPage: 5,

			mediaQuery : "max",
			breakpoints: {
				1200: {
					perPage: 4,
				},
			
				768: {
					perPage: 3,
				},
				576: {
					perPage: 2,
				},
				300: {
					perPage: 1,
				},
			}
		}).mount();
	}
}
export function setupSponsorAutoScroll(){
	var elms = document.getElementsByClassName("sponsor-autoscroll");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "loop",
			drag   : "free",
			pagination: false,
			arrows    : false,
			focus  : "center",
			perPage: 8,
			autoScroll: {
				speed: 1,
				rewind: true
			},
			mediaQuery : "max",
			breakpoints: {
				1200: {
					perPage: 6,
				},
				976: {
					perPage: 4,
				},
				768: {
					perPage: 3,
				},
				576: {
					perPage: 2,
				},
				300: {
					perPage: 1,
				},
			}
		}).mount( { AutoScroll } );
	}
}


export const getRandomInt = (max) => {
	return Math.floor(Math.random() * max);
};

export const getListOfRandomInt = (number_of_random_number, max) => {
	let rand_list = new Array();
	while(rand_list.length < number_of_random_number && rand_list.length < max){
		let random = getRandomInt(max);
		if (rand_list.includes(random)){
			continue;
		}
		rand_list.push(random);
	}
	return rand_list;
};


export function setupMoreNews(){
	const news_wrapper = document.querySelector(".news-items");
	if (news_wrapper){
		fetch("/content/news/?format=json").then(
			response => response.json()
		).then(
			data => {
				if("content_items" in data){
					const random_items = getListOfRandomInt(2, data["content_items"].length);
					random_items.forEach(random_int => {
						const el = data["content_items"][random_int];
						let anchor = document.createElement("a");
						anchor.href = el.url;
						let card = document.createElement("div");
						card.className  = "card";

						if ("icon_url" in el && el["icon_url"]){
							let img_wrapper = document.createElement("div");
							img_wrapper.className = "img-wrapper";

							let img = document.createElement("img");
							img.src = el["icon_url"];
							img_wrapper.append(img);
							card.append(img_wrapper);
						}
						if ("title" in el && el["title"]){
							let title = document.createElement("p");
							title.innerHTML = el["title"];
							title.className = "news-title";
							card.append(title);
						}
					
						let summary = "";
						if ("summary" in el && el["summary"] != ""){
							summary = el["summary"];
						}
						else if("teaser" in el && el["teaser"]){
							summary = el["teaser"];
						}
						
						if (summary != ""){
							let summary_el = document.createElement("p");
							summary_el.innerHTML = summary;
							summary_el.className = "news-summary";
							card.append(summary_el);
						}

						
						let read_more = document.createElement("p");
						read_more.innerHTML = "Read more";
						read_more.className = "news-link";
						card.append(read_more);
						
						anchor.append(card);
						news_wrapper.append(anchor);
					});
				}
			}
		);
	}
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});